<template>
  <div class="d-flex align-items-center album-container">
    <a
      v-if="IOSButton && start_btn"
      class="position-fixed page-btn d-flex justify-content-center align-items-center"
      @click="clickStart"
    >
      播放音效
    </a>
    <!--    <audio autoplay>-->
    <!--      <source src="/story/album.mp3" type="audio/mpeg" />-->
    <!--    </audio>-->
    <actAlbum class="album-view" :animateData="albums_one" :key="albumIndex"></actAlbum>
  </div>
</template>

<script>
import CharacterFromVuex from "../Mixins/CharacterFromVuex";
import UserFromVuex from "../Mixins/UserFromVuex";
import actAlbum from "@/views/part/actAlbum";
import { mapGetters } from "vuex";
import { isIOS } from "@/utils/OS";
import HowlAudio from "@/Mixins/HowlAudio";

export default {
  name: "story",
  mixins: [CharacterFromVuex, UserFromVuex, HowlAudio],
  components: {
    actAlbum
  },
  data() {
    return {
      start_btn: true,
      timer: null,
      albumIndex: 0,
      albums: [
        {
          words: "即使那些年熟悉的街景不再了\\r 你們的名字卻從此不曾忘記",
          picture: "/story/sample.jpg",
          url: "story/nanyang/data.json"
        },
        {
          words: "像發了瘋一樣練習一遍再一遍\\r 才能讓你看到零NG的那個我",
          picture: "/story/sample.jpg",
          url: "story/cks/data.json"
        },
        {
          words: "如此成熟美艷的學姊，\\r我就讓賢讓你帶回家！",
          picture: "/story/sample.jpg",
          url: "story/fg/data.json"
        },
        {
          words: "說不出口的那些話都在旋律裡，\\r知道或不知道，都好",
          picture: "/story/sample.jpg",
          url: "story/csh/data.json"
        },
        {
          words: "沒關係啊！大家一起單身啊！\\r  脫單者阿魯巴！",
          picture: "/story/sample.jpg",
          url: "story/ximen/data.json"
        },
        {
          words: "打架歸打架，手機不要往我臉上砸啦！",
          picture: "/story/sample.jpg",
          url: "story/park228/data.json"
        },
        {
          words: "即使各奔東西，我們仍然要常常聯繫！",
          picture: "/story/sample.jpg",
          url: "story/station/data.json"
        },
        {
          words: "結尾",
          picture: "/story/sample.jpg",
          url: "story/end/data.json"
        }
      ],
      audio: null
    };
  },
  computed: {
    ...mapGetters("Result", ["photos"]),
    IOSButton() {
      return isIOS();
    },
    albums_render() {
      return this.albums.map((d, index) => {
        let photo = "";
        switch (index) {
          case 0:
            photo = this.photos?.[1];
            break;
          case 1:
            photo = this.photos?.[2];
            break;
          case 2:
            photo = this.photos?.[5];
            break;
          case 3:
            photo = this.photos?.[3];
            break;
          case 4:
            photo = this.photos?.[6];
            break;
          case 5:
            photo = this.photos?.[4];
            break;
          case 6:
            photo = this.photos?.[7];
            break;
        }
        d.picture = photo;
        return d;
      });
    },
    albumCount() {
      return this.albums.length;
    },
    albums_one() {
      return this.albums_render?.[this.albumIndex];
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.albumIndex + 1 >= this.albumCount) {
        clearTimeout(this.timer);
        this.$router.replace("finalPanel");
        return;
      }
      this.albumIndex++;
    }, 10000);
    this.audio = this.makeAudio("/story/album.mp3");
    this.audio.play();
  },
  methods: {
    clickStart() {
      console.log("IOS play");
      this.start_btn = false;
      this.audio.play();
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<style scoped>
.album-container {
  min-height: 100vh;
}
.album-view {
  width: 100%;
  height: 100vh;
}
</style>
