<template>
  <div>
    <div class="full-video position-fixed open-mc" :class="{ fadeOut: fadeOut }">
      <vimeo :vimeo_key="'end'" @ended="playEnd" @play="play" @timeupdate="timeupdate"></vimeo>
    </div>
    <div class="full-panel">
      <h1 class="text-center">好感值結算</h1>
      <ul class="list-unstyled team-score-list">
        <li class="form-row" v-for="member in scores" :key="member.user_id">
          <div class="col-auto">
            <figure class="circle my-0 mr-2">
              <!-- <img class="cover-fit" :src="showAvatar(member.user.avatar)" /> -->
              <img class="cover-fit" :src="avatar(member)" />
            </figure>
          </div>
          <div class="col">
            <div>
              <div class="score-text d-flex">
                <span class="mr-2 text-gray" v-if="member.character">{{ member.character.name }}</span
                ><span>{{ member.user.nickname }}</span>
                <span class="ml-auto font-weight-bold">{{ member.score }}</span>
              </div>
              <div class="team-favorite-progress d-flex align-items-center position-relative final-score">
                <div class="favorite-progress favorite-small">
                  <div class="favorite-progress-bar" v-bind:style="{ width: scorePercent(member.score) + '%' }"></div>
                </div>
                <!-- <svg class="position-absolute score-heart">
                  <use xlink:href="#icon-heart-s" class="heart-shadow" v-bind:class="heartColor(member.score)"></use>
                </svg> -->
                <div class="score-content position-absolute">{{ member.scoreContent }}</div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <button class="btn btn-primary btn-block" @click="next">繼續</button>
    </div>
  </div>
</template>

<script>
import Avatar from "../Mixins/Avatar";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import UserFromVuex from "../Mixins/UserFromVuex";
import { mapGetters } from "vuex";
import vimeo from "@/views/part/vimeo";

export default {
  name: "scoreResult",
  mixins: [Avatar, TeamFromVuex, UserFromVuex],
  components: {
    vimeo
  },
  computed: {
    ...mapGetters("Video", ["getVideoPath"]),
    scores() {
      return this.members
        .map(d => {
          let score = this.score_map?.[d.user_id.toString()] || this.score_map?.[d.user_id] || 0;
          let percent = score / 3;
          let character = this.character_map.find(d2 => d2.user_id == d.user_id)?.character;
          return { ...d, score, character, percent };
        })
        .sort((a, b) => b.score - a.score);
    }
  },
  data() {
    return {
      playBtn: true,
      fadeOut: false,
      memberList: []
    };
  },
  methods: {
    heartColor(score) {
      if (score == 100) {
        return "fill-heart";
      }
      return "fill-heart-not";
    },
    next() {
      this.$router.replace("winner");
    },
    playEnd() {
      // this.$refs.endVideo.play();
      this.playBtn = false;
      this.fadeOut = true;
    },
    play: function() {
      this.playBtn = false;
    },
    avatar(member) {
      if (member.user.avatar.includes("default")) return this.showAvatar(member.character.picture);
      return member.user.avatar;
    },
    maxScores() {
      let scores = this.scores.map(member => member.score);
      return scores.sort((a, b) => b - a)[0];
    },
    scorePercent(score) {
      let max = this.maxScores();
      return Math.floor((score / max) * 100);
    },
    timeupdate(event) {
      if (event.currentTime > 9) {
        this.fadeOut = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";

h1 {
  padding: 30px 0 20px;
  font-size: 30px;
}

.text-gray {
  color: $light-color;
}

.score-text {
  padding: 0px 10px 0px 0;
  span:last-child {
    color: #fd5f7e;
  }
}
.final-score {
  padding: 10px 10px 10px 0;
}

.score-content {
  right: 0;
  top: 4px;
  font-size: 12px;
}

.team-score-list {
  padding: 20px 0;
  li {
    padding: 10px 0;
  }
  figure {
    width: 60px;
    height: 60px;
  }
  .score-heart {
    width: 30px;
    height: 30px;
    right: 50px;
    top: 3px;
    filter: drop-shadow(0 3px 2px rgba(0, 0, 0, 0.16));
  }
}

.full-video {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  transition: 1s;
  &.fadeOut {
    pointer-events: none;
    opacity: 0;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
