var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.right",value:(
    function () {
      _vm.slideTo(_vm.index - 1);
    }
  ),expression:"\n    () => {\n      slideTo(index - 1);\n    }\n  ",arg:"swipe",modifiers:{"right":true}},{name:"touch",rawName:"v-touch:swipe.left",value:(
    function () {
      _vm.slideTo(_vm.index + 1);
    }
  ),expression:"\n    () => {\n      slideTo(index + 1);\n    }\n  ",arg:"swipe",modifiers:{"left":true}}],ref:"slide",staticClass:"position-relative card-slides",style:({ height: _vm.height + 'px' })},[_c('div',{staticClass:"selectedFrame"}),_c('ul',{staticClass:"list-unstyled position-absolute hint-slides d-flex",style:({ width: _vm.slideWidth + 'px', left: _vm.slideToOffset })},_vm._l((_vm.slides),function(photo,key){return _c('li',{key:key,ref:"album",refInFor:true},[_c('img',{attrs:{"src":_vm.getPhotoFrame(key),"alt":""}}),_c('img',{staticClass:"photo",attrs:{"src":photo,"alt":""}})])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }