var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.right",value:(
    function () {
      _vm.slideTo(_vm.index - _vm.moveNumber);
    }
  ),expression:"\n    () => {\n      slideTo(index - moveNumber);\n    }\n  ",arg:"swipe",modifiers:{"right":true}},{name:"touch",rawName:"v-touch:swipe.left",value:(
    function () {
      _vm.slideTo(_vm.index + _vm.moveNumber);
    }
  ),expression:"\n    () => {\n      slideTo(index + moveNumber);\n    }\n  ",arg:"swipe",modifiers:{"left":true}}],ref:"slide",staticClass:"position-relative card-slides",style:({ height: _vm.height + 'px' })},[(_vm.vimeo)?_c('div',{staticClass:"viemo-cover cover1",style:({ width: _vm.width + 'px', height: _vm.height + 'px' }),on:{"click":function($event){return _vm.playVimeo(0)}}}):_vm._e(),(_vm.vimeo)?_c('div',{staticClass:"viemo-cover cover2",style:({ width: _vm.width + 'px', height: _vm.height + 'px' }),on:{"click":function($event){return _vm.playVimeo(1)}}}):_vm._e(),(_vm.vimeo)?_c('div',{staticClass:"viemo-cover cover3",style:({ width: _vm.width + 'px', height: _vm.height + 'px' }),on:{"click":function($event){return _vm.playVimeo(2)}}}):_vm._e(),(_vm.youtube)?_c('div',{staticClass:"viemo-cover",staticStyle:{"width":"100%","height":"100%"}},[_c('a',{staticStyle:{"display":"block","width":"100%","height":"100%"},attrs:{"href":_vm.slides[this.index],"target":"_blank"}})]):_vm._e(),_c('ul',{staticClass:"list-unstyled position-absolute hint-slides d-flex",style:({ width: _vm.slideWidth + 'px', left: _vm.slideToOffset })},_vm._l((_vm.slides),function(slide,key){return _c('li',{key:key},[(!_vm.vimeo && !_vm.youtube)?_c('a',{attrs:{"href":slide.href,"target":"_blank"}},[_c('img',{attrs:{"src":'img/' + slide.img,"alt":""}})]):_vm._e(),(_vm.vimeo)?_c('vimeo',{attrs:{"vimeo_key":slide,"dataWidth":_vm.width}}):_vm._e(),(_vm.vimeo)?_c('div',{staticClass:"vimeo-title"},[_vm._v(_vm._s(_vm.getScenesName[slide]))]):_vm._e(),(_vm.youtube)?_c('div',[_c('iframe',{attrs:{"width":_vm.width,"height":_vm.height,"src":slide,"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_vm._e()],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }