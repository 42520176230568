<template>
  <div>
    <div class="lavCon" ref="lavContainer"></div>
  </div>
</template>

<script>
import lottie from "lottie-web";
import axios from "axios";

export default {
  name: "actAlbum",
  props: {
    animateData: {
      default: () => {},
      type: Object
    }
  },
  watch: {
    animateData: {
      deep: true,
      handler() {
        this.fetchData();
      }
    }
  },
  data() {
    return {};
  },
  mounted() {
    this.fetchData();
  },
  updated() {
    // this.fetchData()
  },
  methods: {
    fetchData() {
      // let data=require(this.animateData);
      axios.get(this.animateData.url).then(response => {
        // console.log(response)
        let data = JSON.stringify(response.data);
        data = data.replace("text_content", this.animateData.words);
        data = data.replace("album_img", this.animateData.picture);
        // return;
        data = JSON.parse(data);
        lottie.loadAnimation({
          container: this.$refs.lavContainer, // the dom element that will contain the animation
          renderer: "svg",
          // loop: true,
          autoplay: true,
          rendererSettings: {
            progressiveLoad: false
          },
          animationData: data // the path to the animation json
        });
      });
    }
  }
};
</script>

<style scoped>
.lavCon {
  width: 100%;
  height: 100%;
}
</style>
