<template>
  <div
    class="position-relative card-slides"
    ref="slide"
    :style="{ height: height + 'px' }"
    v-touch:swipe.right="
      () => {
        slideTo(index - moveNumber);
      }
    "
    v-touch:swipe.left="
      () => {
        slideTo(index + moveNumber);
      }
    "
  >
    <div
      v-if="vimeo"
      class="viemo-cover cover1"
      :style="{ width: width + 'px', height: height + 'px' }"
      @click="playVimeo(0)"
    ></div>
    <div
      v-if="vimeo"
      class="viemo-cover cover2"
      :style="{ width: width + 'px', height: height + 'px' }"
      @click="playVimeo(1)"
    ></div>
    <div
      v-if="vimeo"
      class="viemo-cover cover3"
      :style="{ width: width + 'px', height: height + 'px' }"
      @click="playVimeo(2)"
    ></div>
    <div v-if="youtube" class="viemo-cover" style="width: 100%;height:100%">
      <a :href="slides[this.index]" target="_blank" style="display:block;width: 100%; height: 100% "></a>
    </div>

    <ul
      class="list-unstyled position-absolute hint-slides d-flex"
      :style="{ width: slideWidth + 'px', left: slideToOffset }"
    >
      <li class="" v-for="(slide, key) in slides" :key="key">
        <a v-if="!vimeo && !youtube" :href="slide.href" target="_blank">
          <img :src="'img/' + slide.img" alt="" />
        </a>
        <vimeo v-if="vimeo" :vimeo_key="slide" :dataWidth="width"></vimeo>
        <div class="vimeo-title" v-if="vimeo">{{ getScenesName[slide] }}</div>
        <div v-if="youtube">
          <iframe
            :width="width"
            :height="height"
            :src="slide"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import vimeo from "./vimeo";
import { mapGetters } from "vuex";

export default {
  components: { vimeo },
  name: "finalPanelSlider",
  props: {
    slides: {
      default: {}
    },
    moveNumber: {
      default: 1
    },
    width: {
      default: false
    },
    height: {
      default: false
    },
    vimeo: {
      default: false
    },
    youtube: {
      default: false
    }
  },
  data() {
    return {
      index: 0,
      // margin: 25,
      slideToOffset: 0,
      slide_length: 0
    };
  },
  computed: {
    ...mapGetters("Video", ["getScenesName"]),
    slideWidth() {
      let length = this.length();
      return length * (this.width + 32);
    },
    slideMargin() {
      return `0 ${(327 - this.width) / 2}px`;
    }
  },
  mounted() {
    this.slide_length = this.length();
    let slideMove = 0 - this.index * this.width;
    this.slideToOffset = slideMove + "px";
  },
  methods: {
    slideTo: function(toIndex) {
      console.log("sldeTo", toIndex, this.index);
      if (toIndex <= 0) {
        console.log("index", toIndex);
        this.slideToOffset = 0 + "px";
        return;
      }
      // let length = this.vimeo ? this.slide_length - 2 : this.slide_length;
      let remain_index = this.slide_length - toIndex;
      if (remain_index < 0 && remain_index < this.moveNumber) return;
      if (remain_index > 0 && remain_index <= this.moveNumber) {
        let diff = this.moveNumber - remain_index;
        this.index = toIndex - diff;
      }
      if (remain_index > this.moveNumber) this.index = toIndex;
      let slideWidth = this.width;
      let a_page = this.index * (slideWidth + 12);
      let moveRange = this.youtube ? a_page * 0.8 : a_page;
      let slideMove = 0 - moveRange;
      this.slideToOffset = slideMove + "px";
    },
    length() {
      return Object.values(this.slides).length;
    },
    playVimeo(more) {
      let index = more ? this.index : this.index + more;
      console.log(index);
      this.$emit("playVimeo", index);
    }
  }
};
</script>

<style scoped lang="scss">
.card-slides {
  width: 100%;
  overflow: hidden;
  margin-bottom: 24px;
  position: relative;
}
.viemo-cover {
  position: absolute;
  z-index: 100;
  top: 0;
  &.cover1 {
    left: 0;
  }
  &.cover2 {
    left: 97px;
  }
  &.cover3 {
    left: 194px;
  }
}
.hint-slides {
  transition: left 0.2s;
  height: 100%;
  position: relative;
  li {
    // width: 260px;
    // margin: 0 16px;
    margin-right: 12px;
    position: relative;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 100%;
    }
    .vimeo-title {
      text-align: center;
    }
  }
}
</style>
