<template>
  <div @click="isCopy = false">
    <div class="copyDiv" v-if="isCopy">已複製</div>
    <div class="vimeoFrame" v-if="vimeoFrame">
      <vimeo :vimeo_key="nowVimeoKey"></vimeo>
      <a @click="switchViemoFrame" class="btn btn-primary btn-block text-white mt-2">返回</a>
    </div>
    <div class="position-relative">
      <img class="w-100 " src="/img/header-clouds.jpg" alt="" />
      <img class="title-logo position-absolute" src="/img/finalPanelTitle.png" alt="" />
    </div>
    <div>
      <div class="div-p ">
        <div class="user-info d-flex justify-content-between align-item-center">
          <div class="d-flex mr-auto align-item-center">
            <div class="team-picture circle col-auto p-0">
              <img class="cover-fit" :src="avatar" alt="" />
            </div>
            <h5>{{ user.nickname }}</h5>
          </div>
        </div>
      </div>
      <div class="div-p">
        <div class="team-info p-3">
          <div class="d-flex justify-content-between align-item-center ">
            <h5>{{ team.title }}</h5>
          </div>
          <div class="hr-div my-3"></div>
          <div class="d-flex justify-content-between align-item-center">
            <h5>遊戲日期</h5>
            <h5>{{ showTodayDate }}</h5>
          </div>
          <div class="d-flex justify-content-between align-item-center ">
            <h5>破關時間</h5>
            <h5>{{ getmicrosecondTime }}</h5>
          </div>
        </div>
      </div>
      <div class="div-p photo-selector">
        <div class="user-info d-flex justify-content-center align-item-center">
          <h3 class="pr-4 card-title">照片回憶</h3>
        </div>
        <photoSlider :slides="changePhotoSequence" @changePhoto="parentMethod"></photoSlider>
        <div class="d-flex justify-content-center py-1">
          <button class="px-5 btn btn-primary text-white btn-icons" style="width:268px" @click="downloadPhoto">
            下載選取照片
            <img src="img/icons/icon-download.png" />
          </button>
        </div>
      </div>
      <div class="div-p">
        <!-- 抽獎 -->
        <div class="shareFrame position-relative">
          <img src="img/shareTag.png" alt="" />
          <div class="shareFrame__copy" @click="copyText"></div>
        </div>
      </div>
      <!-- 重溫劇情 -->
      <div class="div-p">
        <!-- 標頭 -->
        <div class="user-info d-flex justify-content-center align-item-center">
          <h3 class="card-title">重溫劇情</h3>
        </div>
        <finalPanelSliderSmall
          :vimeo="true"
          :slides="scenes"
          :moveNumber="3"
          :width="85"
          :height="180"
          @playVimeo="switchViemoFrame"
        ></finalPanelSliderSmall>
      </div>
      <div class="div-p">
        <div class="user-info d-flex justify-content-center align-item-center">
          <h3 class="card-title">幕後花絮</h3>
        </div>
        <finalPanelSliderSmall
          :youtube="true"
          :slides="behindTheScenes"
          :width="220"
          :height="132"
        ></finalPanelSliderSmall>
      </div>
      <div class="d-flex justify-content-center py-1 btn-icons">
        <button class="px-5 btn btn-primary text-white" @click="goCredit">
          製作人員名單
          <img src="img/icons/members.png" />
        </button>
      </div>
      <h2 class="card-title text-center pt-5 pb-3">隊伍排名與狀態</h2>
      <ul class="list-unstyled final-rank-list">
        <li class="d-flex align-items-center" v-for="(team, index) in gameRank" :key="team.id">
          <div class="rank-num circle col-auto d-flex justify-content-center align-items-center">
            {{ index + 1 }}
          </div>
          <!-- <div class="team-picture circle col-auto p-0">
            <img class="cover-fit" :src="showAvatar(team.picture)" alt="" />
          </div> -->
          <div class="col">
            {{ team.name }}
          </div>
          <div class="col-auto">
            <div class="rank-status">{{ microsecondTime(team.game_length) }}</div>
            <div class="rank-score">隊伍分數：{{ team.total_score }}</div>
          </div>
        </li>
      </ul>
      <!-- 發摟壞主意 -->
      <div class="div-p">
        <div class="user-info d-flex justify-content-center align-item-center">
          <h3 class="card-title">發摟壞主意</h3>
        </div>
        <div class="grey-card mb-3 text-center pb-0">
          <div>玩完了但還意猶未盡嗎？</div>
          <div>關注我們的新遊戲資訊！</div>
          <div>不定期也有優惠活動唷！</div>
          <iframe
            class="mt-3"
            allowtransparency="true"
            scrolling="no"
            src="//www.facebook.com/v2.5/plugins/page.php?href=https://www.facebook.com/BadIdeasStudio/&amp;show_facepile=false&amp;small_header=false&amp;hide_cover=false&amp;show_posts=false&amp;locale=zh_TW"
            style="border: none; overflow: hidden; width: 100%;"
          ></iframe>
        </div>
      </div>
      <div class="div-p">
        <div class="user-info d-flex justify-content-center align-item-center">
          <h3 class="card-title">遊戲推薦</h3>
        </div>
        <finalPanelSliderSmall :slides="games" :width="132" :height="200"></finalPanelSliderSmall>
      </div>
    </div>
    <div class="d-flex justify-content-center mb-3 py-1">
      <router-link :to="{ name: 'homeMap' }">
        <button class="px-5 btn btn-primary text-white btn-icons">
          回到遊戲地圖
          <img src="img/icons/homeMap.png" />
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import TeamFromVuex from "@/Mixins/TeamFromVuex";
import UserFromVuex from "@/Mixins/UserFromVuex";
import CharacterFromVuex from "../Mixins/CharacterFromVuex";

import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ResultMixin from "../Mixins/ResultMixin";
import photoSlider from "@/views/part/finalPanelSlider";
import finalPanelSliderSmall from "@/views/part/finalPanelSliderSmall";
import Vimeo from "./part/vimeo.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "finalPanel",
  mixins: [Avatar, TeamFromVuex, UserFromVuex, CharacterFromVuex, ResultMixin],
  components: {
    photoSlider,
    finalPanelSliderSmall,
    Vimeo
  },
  created() {
    this.selectedVimeoKey();
    this.getGameRankData();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener?.("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters("Video", ["getVimeoKey", "getWinnerVideoPath"]),
    ...mapGetters("Team", ["winner"]),
    avatar() {
      let user = this.user.avatar.includes("default_avatar") ? false : this.user.avatar;
      return user || this.showAvatar(this.character.picture);
    },
    getmicrosecondTime() {
      return this.microsecondTime(this.teamData.game_length);
    },
    showTodayDate() {
      let date = new Date();
      return `${date.getFullYear()} / ${date.getMonth() + 1} / ${date.getDate()} `;
    },
    nowVimeoKey() {
      return this.scenes[this.playVimeoKey];
    },
    changePhotoSequence() {
      let newPhotosSequence = {};
      const seq_map = ["", 1, 2, 4, 6, 3, 5, 7];
      for (let stage in this.photos) {
        newPhotosSequence[seq_map[stage]] = this.photos?.[stage];
      }
      return newPhotosSequence;
    }
  },
  data() {
    return {
      isCopy: false,
      checkPhoto: 0,
      event_index: 0,
      vedio_index: 0,
      selected_photo: 0,
      vimeoFrame: false,
      gameRank: "",
      teamData: "",
      playVimeoKey: "",
      games: [
        { name: "冥古屋", img: "recommend01.jpg", href: "https://www.badideasstudio.com/ancienthouse/" },
        { name: "即將推出", img: "recommend-default.jpg" },
        { name: "即將推出", img: "recommend-default.jpg" }
      ],
      scenes: [],
      behindTheScenes: [
        "https://www.youtube.com/embed/Og3MpoupyWE",
        "https://www.youtube.com/embed/TnGZo1I0Lvs"
        // "https://www.youtube.com/embed/TnGZo1I0Lvs"
      ]
    };
  },
  methods: {
    ...mapActions("Game", ["gameDataResult"]),
    async getGameRankData() {
      this.gameRank = await this.gameDataResult(this.team.game_id);
      this.teamData = this.gameRank.find(item => item.id === this.team.id);
    },
    handleScroll() {
      if (!this.isCopy) {
        return;
      }
      this.isCopy = false;
    },
    microsecondTime(microsecond) {
      let microS = microsecond / 1000;
      if (!microS) {
        return ` 000 時 00 分 00 秒`;
      }
      let a_hour = 3600;
      let hour = Math.floor(microS / a_hour);
      let leave = microS - a_hour * hour;
      let mins = Math.floor(leave / 60);
      let seconds = leave - mins * 60;

      return `${hour} 時 ${mins} 分 ${seconds} 秒`;
    },
    setCover(index) {
      this.checkPhoto = index;
    },
    eventSlideTo() {},
    parentMethod(ele) {
      console.log("parentMethod", ele);
      this.selected_photo = ele;
    },
    swipeLeft() {},
    async copyText() {
      const text = "#那些年我們一起的戀愛滋味 #城市闖關遊戲 @壞主意工作室";
      await navigator.clipboard.writeText(text);
      this.isCopy = true;
    },
    async downloadPhoto() {
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      let frame_img = this.selected_photo.children[0];
      let photo_img = this.selected_photo.children[1];

      canvas.width = 1081;
      canvas.height = 1321;
      let img_p = new Image();
      img_p.setAttribute("crossOrigin", "anonymous");
      img_p.src = photo_img.src + "?refreshCache"; //利用不同的檔名讓瀏覽器重新發送request，才不會導致抓不到cache等後續問題
      //console.log("img_p.src", img_p.src);

      var dataURL = "";
      img_p.onload = function() {
        context.drawImage(img_p, 40, 60, 1000, 1000);
        context.drawImage(frame_img, 0, 0, canvas.width, canvas.height);
        dataURL = canvas.toDataURL("image/png");
        let a = document.createElement("a");
        let event = new MouseEvent("click");
        //let event = new TouchEvent("touchstart"); // 將a的download屬性設置為我們想要下載的圖片名稱，若name不存在則使用『下載圖片名稱』作為默認名稱
        //console.log("event", event);
        a.download = "picture"; // 下載的檔名
        a.href = dataURL;
        //console.log("a.href", a.href);
        a.dispatchEvent(event);
      };
    },
    switchViemoFrame(index) {
      this.vimeoFrame = !this.vimeoFrame;
      if (!this.vimeoFrame) return;
      this.playVimeoKey = index;
    },
    selectedVimeoKey() {
      // this.scenes = this.getVimeoKey.filter(key => key.includes("S"));
      let scenes = ["S00", "S01", "S02-1", "S02-2", "S03", "S03A", "S03B", "S03C", "S04", "S05", "S06", "S07"];
      scenes.push(this.getWinnerVideoPath(this.winner));
      scenes.push("S08");
      this.scenes = scenes;
    },
    goCredit() {
      window.open("https://www.badideasstudio.com/2020/08/theyears-credits/", "_blank");
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";
.copyDiv {
  background-color: #333;
  color: #fff;
  width: 100px;
  padding: 12px 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  z-index: 99;
}
.title-logo {
  width: 62.5%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.logo-title {
  width: 148px;
  height: 79px;
  img {
    width: 100%;
  }
  margin: 8px auto;
}
.btn-icons {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  img {
    height: 24px;
    margin-left: 8px;
  }
}
.vimeoFrame {
  position: fixed;
  height: 100vmax;
  width: 100%;
  top: 0;
  left: 0;
  background: black;
  z-index: 101;
  a {
    width: 80%;
    margin: auto;
  }
}
.card-title {
  font-size: 18px;
}
.div-p {
  padding: 36px 24px 0 24px;
  &.photo-selector {
    padding: 36px 0 0 0;
    overflow: hidden;
    button {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .user-info {
    margin-bottom: 16px;
  }
  .team-picture {
    width: 38px;
    height: 38px;
    margin-right: 13px;
  }
  h5 {
    line-height: 38px;
    font-size: 1.1rem;
    margin: 0;
  }
  .team-info {
    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.16);
    .hr-div {
      border: 1px solid #888888;
    }
  }
  .grey-card {
    width: 100%;
    padding: 16px;
    border-radius: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #d9d9d9;
    b {
      font-size: 1.1rem;
    }
    .hashtag {
      background: #fff;
      color: blue;
      flex: 1 0 0;
      border-radius: 12px;
      font-size: 0.9rem;
    }
    .hashtag-btn {
      background: #888888;
      color: #fff;
      font-weight: bold;
      border-radius: 12px;
      border: none;
    }
  }
  .shareFrame {
    img {
      width: 100%;
    }
    .shareFrame__copy {
      position: absolute;
      left: 27px;
      right: 18px;
      bottom: 19px;
      height: 150px;
    }
  }
}
h2 {
  font-size: 24px;
}
.final-rank-list {
  li {
    padding: 20px 16px;
    @include default-shadow-s;
    .rank-num {
      width: 60px;
      height: 60px;
      @include default-shadow-s;
      font-size: 25px;
      font-weight: bold;
      font-family: U-Fo;
      margin-right: 13px;
    }
    .team-picture {
      width: 38px;
      height: 38px;
      margin-right: 13px;
    }
    .rank-status {
      font-size: 14px;
    }
    .rank-score {
      font-size: 12px;
    }
  }
}
</style>
