<template>
  <div class="full-video position-fixed d-flex align-items-center">
    <vimeo :vimeo_key="winnerVideo" @ended="goEnd"></vimeo>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vimeo from "./part/vimeo";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import TeamFromVuex from "../Mixins/TeamFromVuex";

export default {
  name: "winnerVideo",
  mixins: [WebSocketMixin, TeamFromVuex],
  components: {
    vimeo
  },
  data() {
    return {
      winner: "小希"
    };
  },
  computed: {
    ...mapGetters("Video", ["getWinnerVideoPath"]),
    ...mapGetters("Team", ["winner"]),
    winnerVideo: function() {
      return this?.getWinnerVideoPath(this.winner);
    }
  },
  mounted() {
    let scores = Object.keys(this.score_map).map(key => {
      return {
        user_id: key,
        score: this.score_map[key]
      };
    });
    let winner_id = scores.sort((a, b) => b.score - a.score)?.[0]?.user_id;
    let winner_character = this.character_map.find(d => d.user_id == winner_id)?.character;
    this.winner = winner_character.name;
    this.setWinner(this.winner);
  },
  methods: {
    ...mapActions("Team", ["setWinner"]),
    goEnd() {
      this.$router.replace("endScene");
    }
  }
};
</script>

<style scoped></style>
