<template>
  <div
    class="position-relative card-slides"
    ref="slide"
    :style="{ height: height + 'px' }"
    v-touch:swipe.right="
      () => {
        slideTo(index - 1);
      }
    "
    v-touch:swipe.left="
      () => {
        slideTo(index + 1);
      }
    "
  >
    <div class="selectedFrame"></div>
    <!-- <div class="position-absolute slide-info text-white">{{ index + 1 }}/{{ slides.imgs.length }}</div> -->
    <ul
      class="list-unstyled position-absolute hint-slides d-flex"
      :style="{ width: slideWidth + 'px', left: slideToOffset }"
    >
      <li class="" v-for="(photo, key) in slides" :key="key" ref="album">
        <img :src="getPhotoFrame(key)" alt="" />
        <img class="photo" :src="photo" alt="" />
      </li>
      <!-- <template v-for="(stage, key) in slides">
        <li class="" v-for="(slide, a_index) in stage" :key="key + a_index" ref="album">
          <img :src="getPhotoFrame(key)" alt="" />
          <img class="photo" :src="slide" alt="" />
        </li>
      </template> -->
      <!-- <li class="" v-for="(slide, key) in slides.imgs" :key="key">
        <img ref="slide" v-if="slide.includes('https')" :src="slide" alt="" />
        <img ref="slide" v-else :src="'/img/' + slide" alt="" />
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: "photoSlider",
  props: {
    slides: {
      default: {}
    }
  },
  data() {
    return {
      index: 0,
      width: 260,
      margin: 57.5,
      height: 314,
      slideToOffset: 0,
      slide_length: 0
    };
  },
  computed: {
    slideWidth() {
      let length = this.length();
      return length * (this.width + 32);
    },
    photoData() {
      // console.log(this.$refs);
      return this.$refs.album;
    }
  },
  mounted() {
    // console.log("this.$refs.slide", this.$refs);
    this.slide_length = this.length();
    let slideMove = 0 - this.index * this.width;
    this.slideToOffset = slideMove + "px";
    this.$emit("changePhoto", this.$refs.album?.[0]);
  },
  methods: {
    slideTo: function(index) {
      console.log("sldeTo", index);
      if (index < 0) return;
      if (index >= this.slide_length) return;
      this.index = index;
      let slideWidth = this.width;
      let slideMove = 0 - this.index * (slideWidth + 16);
      if (index === 0) {
        this.slideToOffset = 0 + "px";
        this.$emit("changePhoto", this.$refs.album[this.index]);
        return;
      }
      this.slideToOffset = slideMove + "px";
      console.log(this.$refs.album[this.index]);
      this.$emit("changePhoto", this.$refs.album[this.index]);
    },
    length() {
      return Object.values(this.slides).length;
    },
    getPhotoFrame(key) {
      let frame = "img/photo_frame/";
      switch (key) {
        case "1":
          frame += "nanyang.png";
          break;
        case "2":
          frame += "cks.png";
          break;
        case "3":
          frame += "fg.png";
          break;
        case "4":
          frame += "csh.png";
          break;
        case "5":
          frame += "ximen.png";
          break;
        case "6":
          frame += "228.png";
          break;
        case "7":
          frame += "taipeiSation.png";
          break;
      }
      return frame;
    }
  }
};
</script>

<style scoped lang="scss">
.card-slides {
  width: 100%;
  overflow: visible;
  margin-bottom: 24px;
  position: relative;

  .selectedFrame {
    border: 4px solid #1ba7e9;
    position: absolute;
    left: 53.5px;
    top: -4px;
    bottom: -4px;
    width: 268px;
    z-index: 20;
  }
}

.hint-slides {
  transition: left 0.3s;
  height: 100%;
  margin: 0 57.5px;
  position: relative;
  li {
    width: 260px;
    // margin: 0 16px;
    margin-right: 16px;
    position: relative;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 100%;
    }
    .photo {
      position: absolute;
      left: 18px;
      top: 20px;
      width: 226px;
      height: 226px;
      z-index: -1;
    }
  }
}
</style>
