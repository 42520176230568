<template>
  <div>
    <div class="full-video position-fixed d-flex align-items-center">
      <vimeo :vimeo_key="'S08'" @ended="goAlbum"></vimeo>
    </div>
  </div>
</template>

<script>
import vimeo from "./part/vimeo";

export default {
  name: "endScene",
  data() {
    return {};
  },
  components: {
    vimeo
  },
  computed: {},
  methods: {
    goAlbum: function() {
      this.$router.replace("storyAlbum");
    }
  },
  mounted() {}
};
</script>

<style scoped></style>
